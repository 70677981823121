import {
  DynamicWidgetProps,
  type SquareWidgetType,
  StorefrontWidget,
  SummaryBoxesWidgetType
} from 'ecosystem'
import { sharedWidgetsFactory } from 'storefront-modules/widgets'
import CustomContact from '#/src/custom/components/CustomContact'
import { CategoryToolbarWidgetType } from 'storefront-modules/categories/widgets/CategoryToolbarWidget'
import CustomCategoryToolbarWidget from '#/src/custom/widgets/CustomCategoryToolbarWidget'
import { ProductListWidgetType } from 'storefront-modules/products/widgets/ProductListWidget'
import { ProductListVerticalWidgetType } from 'storefront-modules/products/widgets/ProductListVerticalWidget'
import CustomProductListWidget from './CustomProductListWidget'
import CustomCategoryTreeWidget from '#/src/custom/widgets/CustomCategoryTreeWidget'
import { CustomWideWidget } from '#/src/custom/widgets/CustomWideWidget'
import { CustomSummaryBoxesWidget } from '#/src/custom/widgets/CustomSummaryBoxesWidget'

// declare here the widget extensions names. No need to re declare the overriden shared widgets
type WidgetsExtension = 'CONTACT_FORM'

export const widgetsConfig = (
  widget: StorefrontWidget<unknown>,
  // These can be used to create dynamic widgets
  dynamicProps?: DynamicWidgetProps
) => {
  return sharedWidgetsFactory<WidgetsExtension>(widget, {
    dynamicProps,
    extensions: {
      CONTACT_FORM: <CustomContact withDropZone />,

      CATEGORY_TREE: (
        <CustomCategoryTreeWidget
          widget={widget as StorefrontWidget<CategoryToolbarWidgetType>}
          {...{ dynamicProps }}
        />
      ),

      CATEGORY_TOOLBAR: (
        <CustomCategoryToolbarWidget
          widget={widget as StorefrontWidget<CategoryToolbarWidgetType>}
          {...{ dynamicProps }}
        />
      ),

      PRODUCT_LIST: (
        <CustomProductListWidget
          type="PRODUCT_LIST"
          widget={widget as StorefrontWidget<ProductListWidgetType>}
          {...{ dynamicProps }}
        />
      ),

      PRODUCT_LIST_1: (
        <CustomProductListWidget
          type="PRODUCT_LIST_1"
          widget={widget as StorefrontWidget<ProductListVerticalWidgetType>}
          {...{ dynamicProps }}
        />
      ),

      CAMPAIGN_WIDGET: (
        <CustomWideWidget
          priority
          widget={widget as StorefrontWidget<SquareWidgetType>}
          widgetId={(widget as StorefrontWidget<SquareWidgetType>).widget.name}
        />
      ),

      SQUARE_LINK: (
        <CustomWideWidget
          widget={widget as StorefrontWidget<SquareWidgetType>}
          widgetId={(widget as StorefrontWidget<SquareWidgetType>).widget.name}
        />
      ),

      SUMMARY_BOXES_LINK: (
        <CustomSummaryBoxesWidget
          widget={widget as StorefrontWidget<SummaryBoxesWidgetType>}
          widgetId={(widget as StorefrontWidget<SummaryBoxesWidgetType>).widget.name}
        />
      )
    }
  })
}
